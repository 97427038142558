<template>
    <div class="flex justify-end">
        <Button
            v-if="showCancel"
            :label="$translate('Client.Common.Cancel')"
            transparent
            @click="emit('cancel')"/>
        <Button
            :label="label"
            :disabled="disabled"
            @click="emit('click')">
            <template v-if="!noIcon" #right>
                <CIcon name="arrow-right" width="8" height="8"/>
            </template>
        </Button>
    </div>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';

defineProps<{
    label: string
    disabled?: boolean
    noIcon?: boolean
    showCancel?: boolean
}>();

const emit = defineEmits(['click', 'cancel']);
</script>
