<template>
    <DeliveryLayout
        :terms-and-conditions-text="termsAndConditionsText"
        :is-submitting="submitting"
        :is-existing-order="true"
        @continue-to-payment="continueToPayment">
        <!-- Delivery Time -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.DeliveryTime" icon-name="clock">
            <div v-if="deliveryState.existingOrder?.timeSlot" class="font-bold sm:flex">
                <div>
                    <span class="capitalize">{{ getWeekday(deliveryState.existingOrder.timeSlot.from) }}</span>, {{ new Date(deliveryState.existingOrder.timeSlot.from).getDate() }}. {{ getMonth(deliveryState.existingOrder.timeSlot.from) }}
                </div>
                <div>
                    <span class="hidden sm:inline">&nbsp;</span>{{ $translate('Client.Common.Between') }} {{ getTime(deliveryState.existingOrder.timeSlot.from) }} - {{ getTime(deliveryState.existingOrder.timeSlot.to) }}
                </div>
            </div>
        </DeliverySection>

        <!-- Address -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.PickUpPoint" icon-name="store">
            <div>
                <div v-if="deliveryState.existingOrder?.store" class="mb-10">
                    <ChainLogo :chain="deliveryState.existingOrder.store.chain"/>
                    <div class="mt-10">
                        <div>{{ deliveryState.existingOrder.store.address.street }} {{ deliveryState.existingOrder.store.address.houseNumber }}</div>
                        <div>{{ deliveryState.existingOrder.store.address.zipCode }} {{ deliveryState.existingOrder.store.address.city }}</div>
                    </div>
                </div>
            </div>
        </DeliverySection>

        <!-- Replacement products -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.ReplacementProducts" icon-name="basket">
            <div>
                {{ $translate('Client.CheckoutPage.Delivery.ReplacementProductsDescription') }}
            </div>
            <div class="mt-15 space-y-10">
                <div>
                    <InputRadio
                        name="replacementOption"
                        :disabled="true"
                        :label="$translate('Client.CheckoutPage.Delivery.DoNotReplaceProducts')"
                        :value="ProductReplacementOptions.DoNotReplaceAnyProducts"
                        :checked="selectedOption === ProductReplacementOptions.DoNotReplaceAnyProducts"/>
                </div>
                <div>
                    <InputRadio
                        name="replacementOption"
                        :disabled="true"
                        :label="$translate('Client.CheckoutPage.Delivery.ReplaceWithSimilarProducts')"
                        :value="ProductReplacementOptions.ReplaceWithSimilarProducts"
                        :checked="selectedOption === ProductReplacementOptions.ReplaceWithSimilarProducts"/>
                </div>
            </div>
        </DeliverySection>
    </DeliveryLayout>
</template>

<script setup lang="ts">
import { redirectToPayment, setOrderProcessingInfo } from '@/project/apis/commerce/checkoutApi';
import { useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { getWeekday, getMonth, getTime, getDate } from '@/project/utils/datetime';
import { Ref, ref } from 'vue';
import InputRadio from '@/project/forms/InputRadio.vue';
import { ProductReplacementOptions } from '@/api/commerce';
import ChainLogo from '@/project/pages/checkout-page/delivery/ChainLogo.vue';
import { trackCheckoutOption } from '@/project/tracking/tracking.service';
import DeliveryLayout from '@/project/pages/checkout-page/delivery/DeliveryLayout.vue';
import DeliverySection from '@/project/pages/checkout-page/delivery/DeliverySection.vue';

defineProps<{
    termsAndConditionsText: string
}>();

const { deliveryState } = useDeliveryState();

const selectedOption: Ref<ProductReplacementOptions> = ref(deliveryState.existingOrder?.selectedProductReplacementOption || ProductReplacementOptions.DoNotReplaceAnyProducts);
const submitting = ref(false);

async function continueToPayment() {
    if (deliveryState.existingOrder) {
        submitting.value = true;
        trackCheckoutOption(3, undefined, `Existing pickup_${getDate(deliveryState.existingOrder.timeSlot?.from || '')} ${getTime(deliveryState.existingOrder.timeSlot?.from || '')} - ${getTime(deliveryState.existingOrder.timeSlot?.to || '')}_${deliveryState.existingOrder.store?.name}_Note_${deliveryState.existingOrder.selectedProductReplacementOption}`);
        await setOrderProcessingInfo(null, null, selectedOption.value);
        await redirectToPayment();
        submitting.value = false;
    }
}
</script>
