<template>
    <template v-if="wideLayout">
        <div class="grid py-8 w-full px-30 grid-cols-24 bg-backgroundContrastBlue">
            <div class="col-span-6 font-bold">
                {{ props.name }}
            </div>
            <div class="col-span-4 text-right">
                {{ $translate('Client.BasketPage.Unit') }}
            </div>
            <div class="col-span-4 text-right">
                {{ $translate('Client.BasketPage.Quantity') }}
            </div>
            <div class="col-span-3 text-right">
                {{ $translate('Client.BasketPage.UnitPrice') }}
            </div>
            <div class="col-span-4 text-right">
                {{ $translate('Client.BasketPage.TotalDiscount') }}
            </div>
            <div class="col-span-3 text-right">
                {{ $translate('Client.BasketPage.Total') }}
            </div>
        </div>
    </template>
    <template v-else>
        <div class="grid py-8 pr-10 pl-20 w-full grid-cols-24 bg-backgroundContrastBlue">
            <div class="col-span-10 font-bold text-bottom">
                {{ props.name }}
            </div>
            <div class="col-span-4 justify-center text-bottom">
                {{ $translate('Client.BasketPage.Quantity') }}
            </div>
            <div class="col-span-5 justify-end text-bottom">
                {{ $translate('Client.BasketPage.UnitPrice') }}
            </div>
            <div class="col-span-5 justify-end text-bottom">
                {{ $translate('Client.BasketPage.Total') }}
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
const props = defineProps<{
    name: string;
    wideLayout: boolean;
}>();

</script>

<style scoped>
    .text-bottom {
        @apply flex items-end text-12;
    }
</style>
