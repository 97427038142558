<template>
    <li>
        <RouterLink v-if="menuItem.children.length > 0" :to="menuItem.url" :class="menuItem.selected ? 'text-link font-bold' : 'text-link'">
            {{ menuItem.name }}
        </RouterLink>
        <RouterLink v-else :to="menuItem.url" :class="menuItem.selected ? 'text-black font-bold' : 'text-link'" @click="emit('select-leaf')">
            {{ menuItem.name }}
        </RouterLink>
        <ul v-if="menuItem.children.length > 0" class="overflow-hidden pb-20 space-y-20 border-b transition-all duration-200 ease-in-out pl-50 -mx-30 mt-15 border-b-background">
            <ContentNavigationBranch v-for="item in menuItem.children"
                                     :key="item.url"
                                     :menu-item="item"
                                     @select-leaf="emit('select-leaf')"/>
        </ul>
    </li>
</template>

<script lang="ts" setup>
import { SiteMenuItem } from '@/api/cms';

defineProps<{
    menuItem: SiteMenuItem,
}>();

const emit = defineEmits(['select-leaf']);
</script>
