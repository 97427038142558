import { accessToken } from '@/project/authentication/authentication';
import globalAxios, { AxiosHeaders } from 'axios';
import { handleError } from './errorHandler';

globalAxios.interceptors.request.use((config) => {
    const headers = new AxiosHeaders(config.headers);
    headers.set('Authorization', `Bearer ${accessToken.value}`);
    if (!config.headers?.Authorization && accessToken.value) {
        config.headers = headers;
    }
    return config;
});

globalAxios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    handleError(error);
    return Promise.reject(error);
});
