/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductReplacementOptions {
    DoNotReplaceAnyProducts = 'DoNotReplaceAnyProducts',
    ReplaceWithSimilarProducts = 'ReplaceWithSimilarProducts'
}


