<template>
    <div class="grid grid-cols-12">
        <span class="col-span-5 pb-5">{{ $translate('Client.OrderPage.DeliveryTime') }}</span>
        <span class="col-span-7 pb-5">
            <span class="capitalize">{{ getWeekday(model.timeSlot?.from!, true) }}</span>
            {{ getDate(model.timeSlot?.from!, true) }}
            {{ getTime(model.timeSlot?.from!) }}-{{ getTime(model.timeSlot?.to!) }}

        </span>
        <span class="col-span-5 pb-5">{{ $translate('Client.OrderPage.DeliveryType') }}</span>
        <span class="col-span-7 pb-5">{{ getDeliveryType(model) }}</span>
        <span class="col-span-5 pb-5">{{ $translate('Client.OrderPage.OrderNumber') }}</span>
        <span class="col-span-7 pb-5">{{ model.orderId }}</span>
        <span class="col-span-5 pb-5">{{ $translate('Client.OrderPage.Status') }}</span>
        <span class="col-span-7 pb-5">{{ $translate(`Client.OrderPage.OrderStatus.${model.status}`) }}
        </span>
        <span class="col-span-5">{{ $translate('Client.BasketPage.Total') }}</span>
        <span class="col-span-7">{{ formatPrice(model.total) }}</span>
        <span v-if="!minimal" class="col-span-12 py-20">
            <Button :primary="true" class="mx-auto" :label="$translate('Client.OrderPage.ViewOrder')" @click="emit('click', model)"/>
        </span>
    </div>
</template>

<script lang="ts" setup>
import { OrderViewModel } from '@/api/commerce';
import { getWeekday, getDate, getTime } from '@/project/utils/datetime';
import { formatPrice } from '@/project/products/priceFormatter';
import Button from '@/project/components/button/Button.vue';
import { getDeliveryType } from '@/project/apis/commerce/checkoutApi';

defineProps<{
    minimal?: boolean,
    model: OrderViewModel
}>();

const emit = defineEmits(['click']);

</script>
