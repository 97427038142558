<template>
    <transition-group name="list-complete">
        <div v-for="message in messages"
             :key="message.description"
             class="list-complete-item"
             :class="classes">
            <SystemMessageItem v-if="props.type === message.type"
                               :message="message"
                               :rendered-in-drawer="renderedInDrawer"/>
        </div>
    </transition-group>
</template>

<script setup lang="ts">
import { messages, SystemMessageTypes } from './system-message.service';
import SystemMessageItem from './SystemMessageItem.vue';

const props = defineProps<{
    type: SystemMessageTypes,
    classes?: string,
    renderedInDrawer?: boolean,
}>();
</script>

<style scoped>
.list-complete-move {
  transition: transform 0.8s ease;
}

.list-complete-item {
  transition: all 0.3s ease;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
}
</style>
