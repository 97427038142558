<template>
    <button :key="timeSlot.id"
            class="flex justify-between items-center py-10 w-full rounded-lg px-15"
            :class="{'bg-blue text-white/90': timeSlot.id === selectedId && timeSlot.timeSlotStatus !== TimeSlotStatusViewModel.SoldOut,
                     'bg-disabledBackground': timeSlot.id !== selectedId && timeSlot.timeSlotStatus === TimeSlotStatusViewModel.SoldOut,
                     'bg-backgroundContrastBlue': timeSlot.id !== selectedId && timeSlot.timeSlotStatus === TimeSlotStatusViewModel.Available || timeSlot.timeSlotStatus === TimeSlotStatusViewModel.Selected,
                     'hover:bg-blue/20 bg-backgroundContrastBlue cursor-pointer transition-colors duration-200 ease-in-out': !readonly && timeSlot.id !== selectedId && timeSlot.timeSlotStatus === TimeSlotStatusViewModel.Available,
                     'bg-[#faf1c1]': timeSlot.id === selectedId && timeSlot.timeSlotStatus === TimeSlotStatusViewModel.SoldOut,
                     'cursor-default': readonly}"
            :disabled="timeSlot.timeSlotStatus === TimeSlotStatusViewModel.SoldOut"
            @click="!readonly && emit('onSelect', timeSlot)">
        <div :class="{'text-black/40': timeSlot.id !== selectedId && timeSlot.timeSlotStatus === TimeSlotStatusViewModel.SoldOut, 'font-bold': timeSlot.timeSlotStatus !== TimeSlotStatusViewModel.SoldOut}">
            {{ getTime(timeSlot.from) }} - {{ getTime(timeSlot.to) }}
        </div>
        <div v-if="timeSlot.timeSlotStatus === TimeSlotStatusViewModel.SoldOut" :class="{'text-black/40': timeSlot.id !== selectedId}">
            {{ $translate('Client.CheckoutPage.Delivery.TimeSlotSoldOut') }}
        </div>
        <div v-else>
            {{ formatPrice(timeSlot.price) }}
            <!-- Nice to have for debugging: -->
            <!-- <span class="text-fadedText text-10">{{ timeSlot.id }}</span> -->
        </div>
    </button>
</template>

<script setup lang="ts">
import { TimeSlotViewModel, TimeSlotStatusViewModel } from '@/api/commerce';
import { formatPrice } from '@/project/products/priceFormatter';
import { getTime } from '@/project/utils/datetime';

defineProps<{
    timeSlot: TimeSlotViewModel,
    selectedId?: number,
    readonly?: boolean,
}>();

const emit = defineEmits<{(e: 'onSelect', timeSlot: TimeSlotViewModel): void}>();
</script>
