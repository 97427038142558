<template>
    <div class="flex items-center w-full bg-white border-b border-lightBorder grow" :class="size === 'lg' ? 'min-h-[82px] pl-25' : 'min-h-[60px] pl-20'">
        <div class="mr-10 opacity-50 grayscale w-50 h-50">
            <ResponsiveImage :image-url="imageUrl"
                             :aspect-ratio="1/1"
                             :fixed-width-on-screen="size === 'lg' ? 60 : 50"
                             mode="pad"/>
        </div>
        <div class="flex items-center" :class="size === 'lg' ? 'ml-20' : ''">
            <div class="flex flex-wrap opacity-50 grayscale">
                <span><span class="font-bold">{{ displayName }}</span> {{ $translate('Client.BasketStatus.ItemHasBeenRemoved') }}</span>
            </div>
            <Button :label="$translate('Client.Common.Undo')" transparent size="small" @click="emit('undo')"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Button from '@/project/components/button/Button.vue';

defineProps<{
    imageUrl: string
    displayName: string
    size?: 'sm' | 'lg'
}>();

const emit = defineEmits(['undo']);
</script>
